import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useCart } from "../context/CartContext";
import { useInfoModal } from "../context/InfoModalContext";
import Settings from "../pages/settings/Settings";

export default function Header() {
  const rotations = useCart();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const closeNav = () => setIsNavCollapsed(true);
  const { showInfoModal } = useInfoModal();

  return (
    <div>
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-primary">
        <div className="container-md d-flex align-items-end">
          <NavLink
            to="/"
            className={"navbar-brand fs-2 p-2"}
            onClick={closeNav}
          >
            <i className="bi bi-triangle-half text-danger"></i>{" "}
            <span className="d-none d-sm-inline">Widget Data</span>
          </NavLink>
          <button
            className="navbar-toggler d-lg-none align-self-center"
            type="button"
            aria-controls="collapsibleNavId"
            aria-expanded={!isNavCollapsed ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`${
              isNavCollapsed ? "hide" : "show"
            } collapse navbar-collapse`}
            id="collapsibleNavId"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 ms-auto text-sm-center h-100 p-2">
              <li className="nav-item">
                <NavLink
                  to="/dashboard"
                  className={"nav-link"}
                  onClick={closeNav}
                >
                  Dashboard
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/about" className={"nav-link"} onClick={closeNav}>
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#"
                  className="nav-link"
                  style={(isActive) => ({
                    color: "rgba(255,255,255,00.55)",
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsNavCollapsed(true);
                    showInfoModal({
                      title: "",
                      message: <Settings />,
                    });
                  }}
                >
                  Settings
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/cart"
                  className={"nav-link position-relative"}
                  onClick={closeNav}
                >
                  <i className="bi bi-cart-fill"></i> Cart
                  {rotations.length > 0 && (
                    <span className="position-absolute top-25 ms-2 translate-middle badge rounded-circle bg-danger">
                      {rotations.length}
                      <span className="visually-hidden">items in cart</span>
                    </span>
                  )}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
