import React, { useReducer, useContext, createContext, useEffect } from "react";
const SettingsStateContext = createContext();
const SettingsDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "utc": {
      let tempHeaderArray = [...state.headerArr];
      tempHeaderArray.find((o) => o.id === "UTCdate").visible =
        action.isChecked;
      tempHeaderArray.find((o) => o.id === "date").visible = !action.isChecked;
      tempHeaderArray.find((o) => o.id === "UTCdepTime").visible =
        action.isChecked;
      tempHeaderArray.find((o) => o.id === "depTime").visible =
        !action.isChecked;
      tempHeaderArray.find((o) => o.id === "date").visible = !action.isChecked;
      tempHeaderArray.find((o) => o.id === "UTCarrTime").visible =
        action.isChecked;
      tempHeaderArray.find((o) => o.id === "arrTime").visible =
        !action.isChecked;
      return {
        ...state,
        showUTC: action.isChecked,
        headerArr: tempHeaderArray,
      };
    }
    case "daynight": {
      let tempHeaderArray = [...state.headerArr];
      tempHeaderArray.find((o) => o.id === "nightTO").visible =
        action.isChecked;
      tempHeaderArray.find((o) => o.id === "dayTO").visible = action.isChecked;
      tempHeaderArray.find((o) => o.id === "nightLDG").visible =
        action.isChecked;
      tempHeaderArray.find((o) => o.id === "dayLDG").visible = action.isChecked;
      tempHeaderArray.find((o) => o.id === "dayBlk").visible = action.isChecked;
      tempHeaderArray.find((o) => o.id === "nightBlk").visible =
        action.isChecked;
      tempHeaderArray.find((o) => o.id === "landing").visible =
        !action.isChecked;
      tempHeaderArray.find((o) => o.id === "takeoff").visible =
        !action.isChecked;

      return {
        ...state,
        showDayNight: action.isChecked,
        headerArr: tempHeaderArray,
      };
    }
    case "timeoutput": {
      let tempHeaderArray = [...state.headerArr];
      tempHeaderArray.find((o) => o.id === "blkTime").visible =
        action.value === "1";
      tempHeaderArray.find((o) => o.id === "blkTimeTenths").visible =
        action.value === "2";
      tempHeaderArray.find((o) => o.id === "blkTimeHrMin").visible =
        action.value === "3";
      //handle day/night block and time format
      tempHeaderArray.find((o) => o.id === "dayBlk").visible =
        action.isChecked && action.value === "1";
      tempHeaderArray.find((o) => o.id === "dayBlkTenths").visible =
        action.isChecked && action.value === "2";
      tempHeaderArray.find((o) => o.id === "dayBlkHrMin").visible =
        action.isChecked && action.value === "3";
      tempHeaderArray.find((o) => o.id === "nightBlk").visible =
        action.isChecked && action.value === "1";
      tempHeaderArray.find((o) => o.id === "nightBlkTenths").visible =
        action.isChecked && action.value === "2";
      tempHeaderArray.find((o) => o.id === "nightBlkHrMin").visible =
        action.isChecked && action.value === "3";
      //handle landings/takeoffs
      tempHeaderArray.find((o) => o.id === "nightTO").visible =
        action.isChecked;
      tempHeaderArray.find((o) => o.id === "dayTO").visible = action.isChecked;
      tempHeaderArray.find((o) => o.id === "nightLDG").visible =
        action.isChecked;
      tempHeaderArray.find((o) => o.id === "dayLDG").visible = action.isChecked;
      tempHeaderArray.find((o) => o.id === "landing").visible =
        !action.isChecked;
      tempHeaderArray.find((o) => o.id === "takeoff").visible =
        !action.isChecked;
      return {
        ...state,
        timeOutput: action.value,
        headerArr: tempHeaderArray,
      };
      // case "timeoutput": {
      //   let tempHeaderArray = [...state.headerArr];
      //   tempHeaderArray.find((o) => o.id === "blkTime").visible =
      //     action.value === "1";
      //   tempHeaderArray.find((o) => o.id === "blkTimeTenths").visible =
      //     action.value === "2";
      //   tempHeaderArray.find((o) => o.id === "blkTimeHrMin").visible =
      //     action.value === "3";
      //   tempHeaderArray.find((o) => o.id === "dayBlk").visible =
      //     action.value === "1";
      //   tempHeaderArray.find((o) => o.id === "dayBlkTenths").visible =
      //     action.value === "2";
      //   tempHeaderArray.find((o) => o.id === "dayBlkHrMin").visible =
      //     action.value === "3";
      //   tempHeaderArray.find((o) => o.id === "nightBlk").visible =
      //     action.value === "1";
      //   tempHeaderArray.find((o) => o.id === "nightBlkTenths").visible =
      //     action.value === "2";
      //   tempHeaderArray.find((o) => o.id === "nightBlkHrMin").visible =
      //     action.value === "3";
      //   return {
      //     ...state,
      //     timeOutput: action.value,
      //     headerArr: tempHeaderArray,
      //   };
    }
    case "deadhead":
      return { ...state, showDeadheads: action.isChecked };

    default:
      throw new Error(`unknown action ${action.type}`);
  }
};
function initSettings(initialSettings) {
  let tempSettings =
    JSON.parse(localStorage.getItem("settings")) || initialSettings;
  if (tempSettings.headerArr.length != initialSettings.headerArr.length)
    return initialSettings; //check if headerArr has changed due to website version
  return tempSettings;
}
export const SettingsProvider = ({ children }) => {
  const initialSettings = {
    showDeadheads: false,
    showUTC: false,
    showDayNight: false,
    timeOutput: "1",
    headerArr: [
      { id: "date", title: "Date", visible: true },
      { id: "UTCdate", title: "Date", visible: false },
      { id: "takeoff", title: "Takeoffs", visible: true },
      { id: "landing", title: "Landings", visible: true },
      { id: "dayTO", title: "Day Takeoffs", visible: false },
      { id: "nightTO", title: "Night Takeoffs", visible: false },
      { id: "dayLDG", title: "Day Landings", visible: false },
      { id: "nightLDG", title: "Night Landings", visible: false },
      { id: "shipnum", title: "Ship Number", visible: true },
      { id: "flightnum", title: "Flight Number", visible: true },
      { id: "actype", title: "Aircraft Type", visible: true },
      { id: "legSta", title: "Leg Type", visible: true },
      { id: "depApt", title: "Dep Airport", visible: true },
      { id: "arrApt", title: "Arr Airport", visible: true },
      { id: "schedBlock", title: "Scheduled Block", visible: false },
      { id: "depTime", title: "Dep Time", visible: true },
      { id: "UTCdepTime", title: "Dep Time", visible: false },
      { id: "arrTime", title: "Arr Time", visible: true },
      { id: "UTCarrTime", title: "Arr Time", visible: false },
      { id: "blkTime", title: "Block Time", visible: true },
      { id: "blkTimeTenths", title: "Block Time", visible: false },
      { id: "blkTimeHrMin", title: "Block Time", visible: false },
      { id: "dayBlk", title: "Day Block", visible: false },
      { id: "dayBlkTenths", title: "Day Block", visible: false },
      { id: "dayBlkHrMin", title: "Day Block", visible: false },
      { id: "nightBlk", title: "Night Block", visible: false },
      { id: "nightBlkTenths", title: "Night Block", visible: false },
      { id: "nightBlkHrMin", title: "Night Block", visible: false },
      { id: "regnum", title: "A/C Reg", visible: true },
    ],
  };
  const [state, dispatch] = useReducer(reducer, initialSettings, initSettings);
  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify(state));
  }, [state]);
  return (
    <SettingsDispatchContext.Provider value={dispatch}>
      <SettingsStateContext.Provider value={state}>
        {children}
      </SettingsStateContext.Provider>
    </SettingsDispatchContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsStateContext);
export const useDispatchSettings = () => useContext(SettingsDispatchContext);
