import {
  CreateInfoArray,
  CreateRotationArray,
  CreateRotationQArray,
} from "./RotationUtils";

function createCsvFromArray(input) {
  let str = "";
  let endline = "\r\n";
  let seperator = ",";
  input.forEach((row) => {
    row.forEach((item) => {
      str += item;
      str += seperator;
    });
    str = str.slice(0, -1);
    str += endline;
  });
  str = "data:text/csv;charset=utf-8," + str;
  return str;
}
export function DownloadCsv(rotation, settings) {
  const rotArr = CreateRotationArray(rotation, settings);
  let filename = CreateFilename(CreateInfoArray(rotation));
  let csvData = createCsvFromArray(rotArr);
  let encodedUri = encodeURI(csvData);
  let link = document.createElement("a");

  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename + ".csv");
  document.body.appendChild(link);
  link.click();
}
export function DownloadQCsv(rotations, settings) {
  const rotArr = CreateRotationQArray(rotations, settings);
  let filename = CreateFilename(["Cart-Download"]);
  let csvData = createCsvFromArray(rotArr);
  let encodedUri = encodeURI(csvData);
  let link = document.createElement("a");

  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename + ".csv");
  document.body.appendChild(link);
  link.click();
}

export function CreateFilename(nameparts = ["file"]) {
  let date = new Date();
  let dateStr =
    date.getFullYear().toString() +
    date.getMonth().toString() +
    date.getDate().toString() +
    date.getHours().toString() +
    date.getMinutes().toString() +
    date.getSeconds().toString();
  let tempstr = nameparts.reduce((a, s) => {
    return a + "-" + s;
  });
  return dateStr + "-" + tempstr;
}
