import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <div className="container-md py-5 ">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button text-center fw-bold fs-4"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Info For Widgets
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                This web application was made for Widgets to be able to take
                flight data from the online crew scheduling portal and download
                it as a <code>.csv</code> file. This file type can then be
                uploaded into each users electronic flight logging software of
                their choice. While this info is also available in PAS, the PAS
                export requires extra manipulation and does not include takeoffs
                and landings.
              </p>
              <p className="text-center">
                *** DISCLAIMER ***
                <br />
                <small>
                  Each pilot is responsible for logging their own correct data
                  for ratings and certifications IAW § 61.51. Review all
                  downloaded content for accuracy.
                </small>
              </p>
              <p>
                <strong>Features:</strong>
              </p>
              <ul>
                <li>Easy cut-and-paste import</li>
                <li>Clean CSV export with headers</li>
                <li>Export UTC or local times</li>
                <li>Show/Hide deadhead legs</li>
                <li>
                  Download rotation separately or add to cart for batch download
                </li>
                <li>
                  Remember user settings between visits from the same browser
                </li>
                <li>
                  Cart is automatically sorted by rotation date and prevents
                  duplicate entries
                </li>
                <li>Auto-approximate* takeoffs & landings into night & day</li>
                <li>Auto-approximate* block time into night/day</li>
                <li>
                  <strong>New in v1.1:</strong> Choice of flight times in
                  tenths, hundredths, or hours/minutes
                </li>
                <li>
                  Aircraft Database last updated: <strong>May 2023</strong>
                </li>

                <small>
                  * day and night calculations can only be approximated using
                  block in/out times instead of takeoff/land times
                </small>
              </ul>

              <p>
                <strong>Instructions:</strong>
              </p>
              <ol>
                <li>Open the crew portal.</li>
                <li>
                  Go to the "Pay/Hours" menu and select "Flight Time Log".
                </li>
                <li>
                  Copy the entire page using the mouse or press <kbd>Ctrl</kbd>+
                  <kbd>A</kbd> then <kbd>Ctrl</kbd>+<kbd>C</kbd> (or{" "}
                  <kbd>&#8984;</kbd>+<kbd>A</kbd> then <kbd>&#8984;</kbd>+
                  <kbd>C</kbd> on Mac)
                  <br />
                  <small>
                    Please note: At least the entire displayed rotation must be
                    copied and pasted. It doesn't hurt to copy and paste the
                    whole page.
                  </small>
                </li>
                <li>
                  Paste the rotation in the input field on the "Dashboard" page.
                </li>
                <li>Press enter or click "Submit".</li>
                <li>Either click "Download Now" or "Add To Cart".</li>
                <li>
                  If you have added rotations to the cart, they will be
                  available for viewing and download on the "Cart" page.
                </li>
              </ol>
              <p>
                If you run into troubles, or you have comments or suggestions,
                please email me at{" "}
                <a href="mailto:matt@goldwingdesigns.com">
                  matt@goldwingdesigns.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed text-center fw-bold fs-4"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Info For Non-Widgets
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                If you're not a Widget or have no clue what I'm talking about,{" "}
                <strong>that's okay.</strong> You can experience the joy of
                flight log parsing along with the rest of us! Use the links
                below to download sample data to cut and paste into the input
                field of the "Dashboard" page.
              </p>
              <div className="container d-flex justify-content-around">
                <Link to="/files/sample1.txt" target="_blank" download>
                  Sample #1
                </Link>
                <Link to="/files/sample2.txt" target="_blank" download>
                  Sample #2
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed text-center fw-bold fs-4"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Info For Programmers
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                This project was created out of professional necessity and
                personal curiosity. The final version of the project is based on
                the <abbr title="MongoDb, Express, React, Node.js">MERN</abbr>{" "}
                stack. The front end was kept as basic as possible with vanilla{" "}
                <a href="https://reactjs.org/">React</a> and vanilla{" "}
                <a href="https://getbootstrap.com/">Bootstrap 5</a> . While
                other tools were available such as React Redux and
                React-Bootstrap, I decided to steer clear of those so that I
                could learn as much as possible for during my first full stack
                build. The server runs on{" "}
                <a href="https://nodejs.org/">Node.js</a> and{" "}
                <a href="https://expressjs.com/">Express</a> and relies on the{" "}
                <a href="https://momentjs.com/timezone/">Moment Timezone</a> and{" "}
                <a href="https://github.com/mourner/suncalc">Suncalc</a>{" "}
                dependencies to resolve day/time calculations. Mongodb was added
                at the end of the project to maintain the aircraft and airport
                databases. The use of a{" "}
                <abbr title="database management system">DBMS</abbr> was largely
                unneeded for the scope of this project and it also slows the
                speed of the calculations. However, the database is included in
                the final version to allow for expanded functionality in the
                future.
              </p>
              <p>
                If you have any questions about the project, or would like
                access to my code on Github, please send me a message at{" "}
                <a href="mailto:matt@goldwingdesigns.com">
                  matt@goldwingdesigns.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
