import React from "react";
import "bootstrap";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { CartProvider } from "./context/CartContext";
import { SettingsProvider } from "./context/SettingsContext";
import { ConfirmModalProvider } from "./context/ConfirmModalContext";
import { InfoModalProvider } from "./context/InfoModalContext";
import "../src/assets/style.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SettingsProvider>
    <CartProvider>
      <ConfirmModalProvider>
        <InfoModalProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </InfoModalProvider>
      </ConfirmModalProvider>
    </CartProvider>
  </SettingsProvider>
);
