import React from "react";
import "../assets/Spinner.css";

export default function LoadingSpinner() {
  return (
    <div
      className="spinner-container"
      style={{ display: "flex", justifyContent: "center", marginTop: "2em" }}
    >
      <div className="loading-spinner"></div>
    </div>
  );
}
