import { createContext, useContext, useState } from "react";
import ConfirmModal from "../components/ConfirmModal";
import { CSSTransition } from "react-transition-group";
import "../assets/ModalAnim.css";

const ConfirmModalContext = createContext();
export const ConfirmModalProvider = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const openModal = ({ title, message, actionCallback }) => {
    setModalIsOpen(true);
    setModalConfig({ title, message, actionCallback });
  };

  const resetModal = () => {
    setModalIsOpen(false);
    setModalConfig({});
  };

  const onConfirm = () => {
    resetModal();
    modalConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetModal();
    modalConfig.actionCallback(false);
  };
  return (
    <ConfirmModalContext.Provider value={{ openModal }}>
      <CSSTransition
        in={modalIsOpen}
        timeout={400}
        classNames="modal"
        unmountOnExit
      >
        <ConfirmModal
          title={modalConfig?.title}
          message={modalConfig?.message}
          onConfirm={onConfirm}
          onDismiss={onDismiss}
        />
      </CSSTransition>

      {children}
    </ConfirmModalContext.Provider>
  );
};

export const useConfirmModal = () => {
  const { openModal } = useContext(ConfirmModalContext);

  const getConfirmation = ({ ...options }) =>
    new Promise((res) => {
      openModal({ actionCallback: res, ...options });
    });

  return { getConfirmation };
};
