import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./pages/about/About";
import Cart from "./pages/cart/Cart";
import Dashboard from "./pages/dashboard/Dashboard";
import Settings from "./pages/settings/Settings";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/settings" element={<Settings />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="*" element={<Navigate to="/dashboard" replace />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
