import React from "react";
import { useDispatchCart } from "../../context/CartContext";
import PrintTable from "../../components/PrintTable";
import { useConfirmModal } from "../../context/ConfirmModalContext";
import { useInfoModal } from "../../context/InfoModalContext";

export default function CartItem({ rotation, index }) {
  const { getConfirmation } = useConfirmModal();
  const { showInfoModal } = useInfoModal();

  const rotObj = rotation;
  const dispatch = useDispatchCart();

  const removeItem = async (index) => {
    const result = await getConfirmation({
      title: "Are you sure?",
      message: "Do you want to delete this rotation from the cart?",
    });
    if (result) {
      dispatch({ type: "REMOVE", index });
    }
  };
  return (
    <div className="row g-0">
      <div className="card mb-3 shadow-lg rounded py-3 py-lg-0">
        <div className="d-flex row g-0 align-items-center justify-content-between">
          <div className="card-body col-lg-8 text-center text-lg-start">
            <h5 className="card-title mb-0">
              Rotation: {rotObj.rotNum} Date: {rotObj.rotDate}
            </h5>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <button
              onClick={(e) => {
                e.stopPropagation();
                showInfoModal({
                  title: "",
                  message: <PrintTable rotation={rotation} />,
                });
              }}
              className="btn btn-primary mx-2"
            >
              View
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                removeItem(index);
              }}
              className="btn btn-outline-danger mx-2"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
