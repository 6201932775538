import React, { useEffect } from "react";
import ReactDom from "react-dom";
import "../assets/ModalStyles.css";

export default function InfoModal({ title, message, onDismiss }) {
  useEffect(() => {
    function keyListener(e) {
      if (e.keyCode === 27 || e.keyCode === 13) {
        e.preventDefault();
        onDismiss();
      }
    }

    document
      .getElementById("infoModal")
      .addEventListener("keydown", keyListener);
  }, []);
  return ReactDom.createPortal(
    <>
      <div
        className="my-modal-overlay modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="title"
        onClick={onDismiss}
      >
        <div
          className="modal-dialog text-center modal-xl"
          id="infoModal"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-content">
            <div
              className="modal-body"
              style={{ maxWidth: "100vw", overflowX: "auto" }}
            >
              <h5 id="title" className="modal-title mb-3">
                {title}
              </h5>
              {message}
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-primary"
                tabIndex="0"
                onClick={onDismiss}
                autoFocus
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
}
