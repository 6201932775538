import React, { useEffect } from "react";

export default function InputFrame({ handleSubmit, disabled }) {
  useEffect(() => {
    function keyListener(e) {
      if (e.which === 13 && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    }
    document
      .getElementById("inputText")
      .addEventListener("keydown", keyListener);
  }, []);
  return (
    <div className="container">
      <form
        className="text-center my-5"
        id="inputForm"
        onSubmit={handleSubmit.bind(this)}
      >
        <div className="mb-3">
          <label
            htmlFor="inputText"
            className="form-label mb-2 visually-hidden"
          >
            Copy and Paste Flight Log Text Here
          </label>
          <textarea
            className="form-control mb-3 firstFocus"
            style={{ resize: "none" }}
            name="inputText"
            id="inputText"
            rows="10"
            cols="30"
            placeholder='Copy and Paste Flight Log Text Here&#10;See "About" Tab For Instructions'
            autoFocus
          ></textarea>
          <button
            type="submit"
            tabIndex="0"
            disabled={disabled}
            className="btn btn-primary"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
