import React from "react";

export default function Footer() {
  return (
    <div
      style={{ fontSize: ".75em" }}
      className="fixed-bottom text-white-50 bg-primary text-center "
    >
      <small>
        Created By{" "}
        <a className="text-white-50" href="https://goldwingdesigns.com">
          Goldwing Designs, LLC
        </a>{" "}
        | Copyright &copy; {new Date().getFullYear()}
      </small>
    </div>
  );
}
