import { createContext, useContext, useState } from "react";
import InfoModal from "../components/InfoModal";
import { CSSTransition } from "react-transition-group";
import "../assets/ModalAnim.css";

const InfoModalContext = createContext();
export const InfoModalProvider = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const openModal = ({ title, message }) => {
    setModalIsOpen(true);
    setModalConfig({ title, message });
  };

  const resetModal = () => {
    setModalIsOpen(false);
    setModalConfig({});
    const focusEl = document.getElementsByClassName("firstFocus");
    if (focusEl.length !== 0) {
      focusEl[0].focus();
    }
    //will return focus to underlying container with class firstFocus
  };

  const onDismiss = () => {
    resetModal();
  };
  return (
    <InfoModalContext.Provider value={{ openModal }}>
      <CSSTransition
        in={modalIsOpen}
        timeout={400}
        classNames="modal"
        unmountOnExit
      >
        <InfoModal
          title={modalConfig?.title}
          message={modalConfig?.message}
          onDismiss={onDismiss}
        />
      </CSSTransition>

      {children}
    </InfoModalContext.Provider>
  );
};

export const useInfoModal = () => {
  const { openModal } = useContext(InfoModalContext);

  const showInfoModal = ({ ...options }) =>
    new Promise((res) => {
      openModal({ actionCallback: res, ...options });
    });

  return { showInfoModal };
};
