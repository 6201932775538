export function CreateRotationArray(rotation, settings) {
  const showDeadheads = settings.showDeadheads;
  const headerArr = settings.headerArr;
  let tempArr = [];
  const rotArr = rotation;
  const visibleHeaders = headerArr.filter((item) => item.visible);
  tempArr.push(visibleHeaders.map((b) => b.title));
  rotArr.legs.forEach((leg) => {
    if (leg.legSta.match(/D|O/) && !showDeadheads) {
      return;
    }
    tempArr.push(visibleHeaders.map((a) => leg[a.id]));
  });
  return tempArr;
}
export function CreateRotationQArray(rotations, settings) {
  const showDeadheads = settings.showDeadheads;
  const headerArr = settings.headerArr;
  let tempArr = [];

  const visibleHeaders = headerArr.filter((item) => item.visible);
  tempArr.push(visibleHeaders.map((b) => b.title));
  rotations.forEach((rotation) => {
    rotation.legs.forEach((leg) => {
      if (leg.legSta.match(/D|O/) && !showDeadheads) {
        return;
      }
      tempArr.push(visibleHeaders.map((a) => leg[a.id]));
    });
  });
  return tempArr;
}
export function CreateInfoArray(rotation) {
  const rotArr = rotation;
  return [rotArr.name, rotArr.rotNum, rotArr.rotDate];
}
