import React from "react";

import {
  useSettings,
  useDispatchSettings,
} from "../../context/SettingsContext";

export default function Settings() {
  let settings = useSettings();
  let dispatch = useDispatchSettings();
  const handleUTCChange = (e) => {
    dispatch({ type: "utc", isChecked: e.target.checked });
  };
  const handleDHChange = (e) => {
    dispatch({ type: "deadhead", isChecked: e.target.checked });
  };
  // const handleDayNightChange = (e) => {
  //   dispatch({ type: "daynight", isChecked: e.target.checked });
  // };
  // const handleTimeOutputChange = (e) => {
  //   dispatch({ type: "timeoutput", value: e.target.value });
  // };
  const handleTimeOutputChange = (e) => {
    dispatch({
      type: "timeoutput",
      value: document.getElementById("timeOutputSelect").value,
      isChecked: document.getElementById("showDayNightSwitch").checked,
    });
  };

  return (
    <div className="container fs-5">
      <div className="form-check form-switch m-3">
        <label className="form-check-label" htmlFor="showUTCSwitch">
          Use UTC Instead Of Local
        </label>
        <input
          className="form-check-input"
          type="checkbox"
          id="showUTCSwitch"
          defaultChecked={settings.showUTC}
          onChange={handleUTCChange}
        />
      </div>
      <div className="form-check form-switch m-3">
        <label className="form-check-label" htmlFor="showDHSwitch">
          Show Deadhead Legs
        </label>
        <input
          className="form-check-input"
          type="checkbox"
          id="showDHSwitch"
          defaultChecked={settings.showDeadheads}
          onChange={handleDHChange}
        />
      </div>
      <div className="form-check form-switch m-3">
        <label className="form-check-label " htmlFor="showDayNightSwitch">
          Show Separate Day/Night Calculations
        </label>
        <input
          className="form-check-input"
          type="checkbox"
          id="showDayNightSwitch"
          defaultChecked={settings.showDayNight}
          onChange={handleTimeOutputChange}
        />
      </div>
      <div className="m-3 d-flex flex-row justify-content-between align-items-center">
        <label
          className="form-check-label flex-shrink-0 me-1"
          htmlFor="timeOutputSelect"
        >
          Time Output Format
        </label>
        <select
          className="form-select form-select-lg ms-1 flex-shrink-1"
          id="timeOutputSelect"
          defaultValue={settings.timeOutput}
          onChange={handleTimeOutputChange}
        >
          <option value="1">Hundreths (H.hh)</option>
          <option value="2">Tenths (H.h)</option>
          <option value="3">Hrs:Mins (H:mm)</option>
        </select>
      </div>
      <small className="fs-6 fst-italic text-muted">
        **Settings Will Be Saved Between Sessions**
      </small>
    </div>
  );
}
