import React, { useReducer, useContext, createContext, useEffect } from "react";
const CartStateContext = createContext();
const CartDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD": {
      let tempstate = [...state, action.rotation];
      tempstate.sort((x, y) => {
        let a = new Date(x.rotEndDate),
          b = new Date(y.rotDate);
        return a - b;
      });
      return tempstate;
    }
    case "REMOVE":
      const newArr = [...state];
      newArr.splice(action.index, 1);
      return newArr;
    case "EMPTY":
      return [];
    default:
      throw new Error(`unknown action ${action.type}`);
  }
};
function initCart(initialCart) {
  return JSON.parse(localStorage.getItem("cart")) || initialCart;
}
export const CartProvider = ({ children }) => {
  const initialCart = [];
  const [state, dispatch] = useReducer(reducer, initialCart, initCart);
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(state));
  }, [state]);
  return (
    <CartDispatchContext.Provider value={dispatch}>
      <CartStateContext.Provider value={state}>
        {children}
      </CartStateContext.Provider>
    </CartDispatchContext.Provider>
  );
};

export const useCart = () => useContext(CartStateContext);
export const useDispatchCart = () => useContext(CartDispatchContext);
