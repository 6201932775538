import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { CreateInfoArray, CreateRotationArray } from "../utils/RotationUtils";
import { useSettings } from "../context/SettingsContext";

export default function PrintTable({ rotation }) {
  const settings = useSettings();
  const [rotationArray, setRotationArray] = useState([]);
  const infoArray = CreateInfoArray(rotation);
  useEffect(() => {
    if (rotation !== undefined) {
      setRotationArray(CreateRotationArray(rotation, settings));
    }
  }, [rotation, settings]);
  return (
    <>
      {rotationArray.length > 0 && (
        <div className="table-responsive">
          <table className="table table-danger table-striped table-bordered table-sm text-center ">
            <caption className="fst-italic">
              Rotation: {infoArray.reduce((prev, curr) => prev + " " + curr)}
            </caption>
            <thead className="table-dark">
              <tr>
                {rotationArray[0].map((item) => (
                  <th key={uuid()}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rotationArray.slice(1).map((rowData) => {
                return (
                  <tr key={uuid()}>
                    {rowData.map((rowItem) => {
                      return <td key={uuid()}>{rowItem}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
