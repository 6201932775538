import React from "react";
import { useCart, useDispatchCart } from "../../context/CartContext";
import CartItem from "./CartItem";
import { useSettings } from "../../context/SettingsContext";
import { DownloadQCsv } from "../../utils/CsvUtils";
import { useConfirmModal } from "../../context/ConfirmModalContext";

export default function Cart() {
  const rotations = useCart();
  const dispatch = useDispatchCart();
  const settings = useSettings();
  const { getConfirmation } = useConfirmModal();
  const handleCartDownload = (e) => {
    if (rotations.length !== 0) {
      e.preventDefault();
      DownloadQCsv(rotations, settings);
    }
  };
  const handleCartEmpty = async (e) => {
    e.preventDefault();
    const result = await getConfirmation({
      title: "Are you sure?",
      message: "Do you want to empty the cart?",
    });
    if (result) {
      dispatch({ type: "EMPTY" });
    }
  };
  if (rotations.length === 0) {
    return (
      <div className="container-lg py-5">
        <h1 className="display-1 text-center pb-5">Cart</h1>
        <div className="text-center mt-5 h-100 text-muted fs-2">
          Cart Is Empty!
        </div>
      </div>
    );
  }
  return (
    <div className="container py-5 ">
      <h1 className="display-1 text-center pb-5">Cart</h1>
      <div className="row">
        <div className="col-md-7 pb-5">
          {rotations.map((rotation, i) => (
            <CartItem key={i} rotation={rotation} index={i} />
          ))}
        </div>
        <div className="col-10 col-md-4 fs-4 mx-auto px-2">
          <button
            className="rounded btn btn-primary w-100 my-2"
            onClick={handleCartDownload}
          >
            Download Cart
          </button>
          <button
            className="rounded btn btn-outline-danger w-100 my-2"
            onClick={handleCartEmpty}
          >
            Empty Cart
          </button>
        </div>
      </div>
    </div>
  );
}
