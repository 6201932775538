import React, { useState } from "react";
import InputFrame from "./InputFrame";
import OutputFrame from "./OutputFrame";
import LoadingSpinner from "../../components/Spinner";
import { useSessionStorage } from "../../utils/AppUtils";
import { useInfoModal } from "../../context/InfoModalContext";

export default function Dashboard() {
  const [rotation, setRotation] = useSessionStorage("rotation", []);
  const [isLoading, setIsLoading] = useState(false);
  const { showInfoModal } = useInfoModal();
  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    let inputForm = document.querySelector("#inputForm");
    let inputText = document.querySelector("#inputText").value;
    inputForm.reset();
    let resp = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "text/plain",
      },
      body: inputText,
    };
    try {
      const response = await fetch("/api/rotation", resp);
      const data = await response.json();
      if (!response.ok) {
        throw new SyntaxError(data.error);
      }
      setIsLoading(false);
      setRotation(data);
    } catch (e) {
      showInfoModal({
        title: "Error",
        message: e.message,
      });
      setIsLoading(false);
    }
  }

  function clearRotationHandler() {
    setRotation([]);
  }

  // function submitOnEnter(e) {
  //   //allows user to press enter to submit document
  //   if (e.which === 13 && !e.shiftKey) {
  //     //allows user to use Shift+enter to make newline

  //     e.preventDefault(); // Prevents the addition of a new line in the text field (not needed in a lot of cases)
  //     handleSubmit(e);
  //   }
  // }

  return (
    <div className="container d-flex flex-column">
      <InputFrame
        handleSubmit={handleSubmit}
        disabled={isLoading}
        // submitOnEnter={submitOnEnter}
      ></InputFrame>

      {rotation.length !== 0 && (
        <>
          <hr></hr>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <OutputFrame
              rotation={rotation}
              clearRotationHandler={clearRotationHandler}
            ></OutputFrame>
          )}
        </>
      )}
    </div>
  );
}
