import React from "react";
import PrintTable from "../../components/PrintTable";
import { DownloadCsv } from "../../utils/CsvUtils";
import { useCart, useDispatchCart } from "../../context/CartContext";
import { useSettings } from "../../context/SettingsContext";
import { useInfoModal } from "../../context/InfoModalContext";

export default function OutputFrame({ rotation, clearRotationHandler }) {
  const dispatch = useDispatchCart();
  const cart = useCart();
  const settings = useSettings();
  const { showInfoModal } = useInfoModal();

  const downloadClickHandler = (e) => {
    e.stopPropagation();
    DownloadCsv(rotation, settings);
  };
  const addToCartClickHandler = (e) => {
    e.stopPropagation();
    const itemToAdd = cart.find((i) => i.rotNum === rotation.rotNum);
    if (!itemToAdd) {
      dispatch({ type: "ADD", rotation: rotation });
      showInfoModal({
        title: "Success",
        message: "Rotation Added To Cart",
      });
    } else {
      showInfoModal({
        title: "Error",
        message: "Rotation Already In Cart",
      });
    }
    clearRotationHandler();
  };

  return (
    <>
      {rotation !== undefined && (
        <>
          <PrintTable rotation={rotation} />
          <div className="container-md d-flex justify-content-center py-2 py-lg-0 mb-4">
            <button
              className="btn btn-primary mx-3 mb-3"
              onClick={downloadClickHandler}
            >
              Download Now
            </button>
            <button
              className="btn btn-primary mx-3 mb-3"
              onClick={addToCartClickHandler}
            >
              Add To Cart
            </button>
          </div>
        </>
      )}
    </>
  );
}
